import React, { lazy, Suspense, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Skeleton, Modal, Form, message } from 'antd';
import axios from 'axios';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main, CardToolbox } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import AddCameraForm from '../../components/forms/AddCameraForm';

function CamerasPage() {
  const dispatch = useDispatch();
  const [cameras, setCameras] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingCamera, setEditingCamera] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchCameras = async () => {
      try {
        const token = Cookies.get('access_token');
        const response = await axios.get('https://backend.bizdin.ai/api/core/cameras', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const camerasWithCamelCase = response.data.map((camera) => ({
          ...camera,
          rtspUrl: camera.rtsp_url,
        }));
        setCameras(camerasWithCamelCase);
      } catch (error) {
        console.error('Error fetching cameras:', error);
        message.error('An error occurred while fetching the cameras.');
      } finally {
        setLoading(false);
      }
    };

    fetchCameras();
  }, [dispatch]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingCamera(null);
    form.resetFields();
  };

  const handleAddCamera = async (cameraData) => {
    try {
      const token = Cookies.get('access_token');
      const response = await axios.post(
        'https://backend.bizdin.ai/api/core/cameras',
        {
          rtsp_url: cameraData.rtspLink,
          name: cameraData.name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 201) {
        message.success('Camera added successfully!');
        setIsModalVisible(false);
        const newCamera = {
          ...response.data,
          rtspUrl: response.data.rtsp_url,
        };
        setCameras([...cameras, newCamera]);
      } else {
        message.error('Failed to add camera.');
      }
    } catch (error) {
      console.error('Error adding camera:', error);
      message.error('An error occurred while adding the camera.');
    }
  };

  const handleEditCamera = (camera) => {
    setEditingCamera(camera);
    setIsModalVisible(true);
    form.setFieldsValue({
      name: camera.name,
      rtspLink: camera.rtspUrl,
    });
  };

  const handleDeleteCamera = async (cameraId) => {
    try {
      const token = Cookies.get('access_token');
      await axios.delete(`https://backend.bizdin.ai/api/core/cameras/${cameraId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      message.success('Camera deleted successfully!');
      setCameras(cameras.filter((camera) => camera.id !== cameraId));
    } catch (error) {
      console.error('Error deleting camera:', error);
      message.error('An error occurred while deleting the camera.');
    }
  };

  const handleUpdateCamera = async (cameraData) => {
    try {
      const token = Cookies.get('access_token');
      const response = await axios.put(
        `https://backend.bizdin.ai/api/core/cameras/${editingCamera.id}/`,
        {
          rtsp_url: cameraData.rtspLink,
          name: cameraData.name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200) {
        message.success('Camera updated successfully!');
        setIsModalVisible(false);
        const updatedCamera = {
          ...response.data,
          rtspUrl: response.data.rtsp_url,
        };
        setCameras(cameras.map((camera) => (camera.id === editingCamera.id ? updatedCamera : camera)));
        setEditingCamera(null);
      } else {
        message.error('Failed to update camera.');
      }
    } catch (error) {
      console.error('Error updating camera:', error);
      message.error('An error occurred while updating the camera.');
    }
  };

  if (loading) {
    return <Skeleton active />;
  }

  const actions = (camera) => (
    <>
      <Link to="#" onClick={() => handleEditCamera(camera)}>
        <UilPlus />
        <span>Edit</span>
      </Link>
      <Link to="#" onClick={() => handleDeleteCamera(camera.id)}>
        <UilPlus />
        <span>Delete</span>
      </Link>
    </>
  );

  return (
    <>
      <CardToolbox>
        <PageHeader
          className="ninjadash-page-header-main"
          backIcon={false}
          title="All cameras"
          buttons={[
            <Link to="#" className="btn-add_new" onClick={showModal}>
              <UilPlus /> Add New Camera
            </Link>,
          ]}
        />
      </CardToolbox>
      <Main>
        <Row gutter={25}>
          {cameras.map((camera) => {
            const TeamCard = lazy(() => import('./overview/TeamCard'));
            const { id } = camera;
            return (
              <Col key={id} xxl={6} lg={8} sm={12} xs={24}>
                <Suspense
                  fallback={
                    <Cards headless>
                      <Skeleton avatar active />
                    </Cards>
                  }
                >
                  <TeamCard camera={camera} actions={actions(camera)} />
                </Suspense>
              </Col>
            );
          })}
        </Row>
      </Main>

      <Modal
        title={editingCamera ? 'Edit Camera' : 'Add New Camera'}
        visible={isModalVisible}
        onOk={() => form.submit()}
        onCancel={handleCancel}
        okText={editingCamera ? 'Update Camera' : 'Add Camera'}
        cancelText="Cancel"
      >
        <AddCameraForm onSubmit={editingCamera ? handleUpdateCamera : handleAddCamera} form={form} />
      </Modal>
    </>
  );
}

export default CamerasPage;
