import React, { useState, useRef, useEffect } from 'react';
import Button from 'antd/es/button';
import Webcam from 'react-webcam';
import PropTypes from 'prop-types'; // Импортируем PropTypes

function WebcamComponent({ isCameraOn, setIsCameraOn }) {
  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const webcamRef = useRef(null);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
      const videoDevices = deviceInfos.filter((device) => device.kind === 'videoinput');
      setDevices(videoDevices);

      if (videoDevices.length > 0) {
        setSelectedDeviceId(videoDevices[0].deviceId);
      }
    });
  }, []);

  const handleDeviceChange = (event) => {
    setSelectedDeviceId(event.target.value);
  };

  const videoConstraints = {
    deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined,
  };

  const toggleCamera = () => {
    setIsCameraOn(!isCameraOn);
  };

  return (
    <div style={{ marginTop: '50px' }}>
      {devices.length > 0 && (
        <>
          <select onChange={handleDeviceChange}>
            {devices.map((device, index) => (
              <option value={device.deviceId} key={index}>
                {device.label || `Camera ${index + 1}`}
              </option>
            ))}
          </select>

          {isCameraOn && (
            <Webcam audio={false} ref={webcamRef} videoConstraints={videoConstraints} screenshotFormat="image/jpeg" />
          )}
        </>
      )}

      <div style={{ marginTop: '20px' }}>
        <Button type="danger" onClick={toggleCamera}>
          {isCameraOn ? 'Turn Off Camera' : 'Turn On Camera'}
        </Button>
      </div>
    </div>
  );
}

// Добавляем валидацию пропсов
WebcamComponent.propTypes = {
  isCameraOn: PropTypes.bool.isRequired,
  setIsCameraOn: PropTypes.func.isRequired,
};

export default WebcamComponent;
